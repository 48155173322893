import React, {useContext} from 'react';
import StepArrowLink from "../components/StepArrowLink";
import VideoLoader from "../components/VideoLoader";
import AppContext from "../AppContext";

const StepWebsiteUrl = () => {

    const title = 'Let’s get this party started.';
    const subtitle = 'First, tell us about yourself.';
    const description = '';

    const {appState, setDomain, setName} = useContext(AppContext);
    const {domain: clientUrl, name: clientName} = appState;

    const renderStepArrowLink = () => {
        return <StepArrowLink to={'/websiteabout'}
                              theme='purple'
                              showTooltip={{
                                  tooltipHeader: `Hello ${clientName}!`,
                                  tooltipText: `Let's see what ${clientUrl} do for you?`}}
                              disabled={!clientUrl || !clientName}
                              disabledMsg={'Drop your details so we know who we talk to :)'} />
    }

    const renderSlideContent = () => {
        return (
            <>
                <div className={'flex flex-col relative p-[2px] z-10 overflow-hidden mb-2'}>
                    <div className={'relative peer z-10 flex w-full border-solid border-color-purple3 border-solid border-[1px] bg-color-white py-2 px-4 rounded-[4px]'}>
                        <input className={'text-color-purple w-full bg-color-transparent outline-none'} type="text" placeholder={'What is your name?'}
                               value={clientName}
                               onChange={(e) => setName(e.target.value)}/>
                    </div>
                    <div className={'flex-shrink absolute z-0 top-0 left-0 w-0 h-0 bg-gradient-to-r from-color-purple to-color-orange peer-focus-within:w-full peer-focus-within:h-full peer-focus-within:bg-white transition-all rounded-md'}> </div>
                </div>
                <div className={'flex flex-col relative p-[2px] z-10 overflow-hidden'}>
                    <div className={'relative peer z-10 flex w-full border-solid border-color-purple3 border-solid border-[1px] bg-color-white py-2 px-4 rounded-[4px]'}>
                        <div className={'flex-shrink text-color-purple'}>https://www.</div>
                        <input className={'text-color-purple w-full bg-color-transparent outline-none'} type="text" placeholder={'your.website'}
                               value={clientUrl}
                               onChange={(e) => setDomain(e.target.value) }/>
                    </div>
                    <div className={'flex-shrink absolute z-0 top-0 left-0 w-0 h-0 bg-gradient-to-r from-color-purple to-color-orange peer-focus-within:w-full peer-focus-within:h-full peer-focus-within:bg-white transition-all rounded-md'}> </div>
                </div>
            </>
        );
    }

    return (
        <div className={'relative z-10 flex flex-row w-full overflow-hidden'}>
            <div className={'relative flex-1 z-20 flex flex-col justify-center'}>
                <div className={'relative flex-col flex justify-center pr-12'}>
                    <h2 className={'text-color-purple text-3xl font-bold pb-2'}>{title}</h2>
                    <h4 className={'text-color-orange font-bold text-lg pb-2'}>{subtitle}</h4>
                    <p className={'text-color-gray pb-4'}>{description}</p>
                    {renderStepArrowLink()}
                    {renderSlideContent()}
                </div>
            </div>
            <div className={'flex flex-1 justify-center relative z-10'}>
                <VideoLoader  video={'StepWebsiteUrl'} />
            </div>
        </div>
    );
};

export default StepWebsiteUrl;

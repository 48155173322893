import React from 'react';
import iZurchLogo from '../img/iZurch_logo.svg';

const Header = () => {
    return (
        <header className={'w-full max-w-4xl min-h-[160px] flex justify-between items-center'}>
            <div className={'flex-grow'}>
                <img className={'w-full max-w-[140px]'} src={iZurchLogo} alt=""/>
            </div>
            <div className={'leading-4 text-color-white'}>
                <span className={'text-3xl font-bold'}>Be Found<span className={'text-color-orange'}>.</span></span><br/>
                <span className={''}>Affordable SEO services from iZurch</span>
            </div>
        </header>
    );
};

export default Header;

import './App.css';
import React, {useEffect, useState} from "react";
import {Routes, Route, useLocation} from "react-router-dom";
import Header from "./components/Header";
import ContentRoot from "./components/ContentRoot";
import Lottie from "react-lottie-player";
import {animated, useSpring} from 'react-spring';

function App() {

    const location = useLocation();
    // console.log(location.pathname.substr(1));

    const bgAnimation = useSpring({
        from: {opacity: 0},
        to: {opacity: 1},
        delay: 3000,
        config: {
            duration: 4000
        },
    })

    /*
    StepIntro
    StepWebsiteUrl
    StepWebsiteAbout
    StepCompetitors
    StepAbout
    StepContacts
    StepBudget
    StepSchedule
    StepFinal
    */

    const [bgLottieSource, setBgLottieSource] = useState();

    useEffect(() => {
        import('./lottie/bgApp.json').then(setBgLottieSource);
    }, []);

    return (
        <div className={'flex flex-col items-center'}>
            <Header/>
            <Routes location={location}>
                <Route index element={<ContentRoot/>}/>
                <Route path="websiteurl" element={<ContentRoot/>}/>
                <Route path="websiteabout" element={<ContentRoot/>}/>
            </Routes>
            {/*<div className={'relative z-20'}>*/}
            {/*    <Link to={'/'} >Home</Link>*/}
            {/*    <Link to={'websiteurl'} >websiteurl</Link>*/}
            {/*    <Link to={'websiteabout'} >websiteabout</Link>*/}
            {/*</div>*/}
            <animated.div style={bgAnimation} className={'absolute z-0 left-0 bottom-0'}>
                {bgLottieSource && <Lottie play={true} loop={true} animationData={bgLottieSource} rendererSettings={
                    {preserveAspectRatio: 'xMidYMax slice'}
                } />}
            </animated.div>
        </div>
    );
}

export default App;


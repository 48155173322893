import React, {useRef, useState} from 'react';
import StepIntro from "../Steps/StepIntro";
import StepWebsiteUrl from "../Steps/StepWebsiteUrl";
import StepWebsiteAbout from "../Steps/StepWebsiteAbout";

import {animated, useSpring, useTransition} from 'react-spring';
import {useLocation} from "react-router-dom";
import LottieBg from "./LottieBg";

const ContentRoot = () => {

    const location = useLocation().pathname.substr(1);

    const [locationRef, setLocationRef] = useState('');

    const [showCover, apiShowCover] = useSpring(() => ({opacity: 0, display: 'none'}));
    const showCoverEnter = {opacity: 1, display: 'block'}
    const showCoverLeave = {opacity: 0, display: 'none', delay: 0, config: {duration: 0}};

    // transitions for slides set for locationRef var set between cover animation sequences (when enter seq finishes)
    const transitions = useTransition(locationRef, {
        from:  { opacity: 0, position: 'absolute', transform: 'scale(0.5)' },
        enter: { opacity: 1, position: 'relative', transform: 'scale(1)' },
        leave: { opacity: 0, position: 'absolute', transform: 'scale(0)', config: {duration: 0} },
    })

    const bgEntryAnimation = useSpring({
        from: { x: 0 },
        to: { x: 1 },
        config: {
            tension: 140, friction: 14, mass: 1
        },
    })

    let readyForNextSlide = useRef(false);

    // static var used to determine what cover theme needs to be set between slides
    const isIntro = useRef(true);

    const lottieAniCompleted = (mode) => {
        if(mode === 'enter'){
            readyForNextSlide.current = true;
            setLocationRef(location);
        } else {
            readyForNextSlide.current = false;
            apiShowCover.start(showCoverLeave);
            isIntro.current = location === '' || location === '/';
        }
    }

    // set cover color theme based on isIntro set after both sequences for cover animation are played
    const coverTheme = isIntro.current ? 'white' : 'purple';

    const renderCover = () => {
        // prevent cover animation and slide change if the same link is clicked
        if(locationRef === location && !readyForNextSlide.current) {
            return null;
        }
        if(!readyForNextSlide.current){
            //if cover animation lottie entry sequence is NOT completed
            apiShowCover.start(showCoverEnter);
            return (
                <animated.div style={showCover} className={'absolute z-50 top-0 left-0 w-full h-full'}>
                    <LottieBg theme={coverTheme} mode={'enter'} completed={lottieAniCompleted} />
                </animated.div>
            );
        } else if(readyForNextSlide.current) {
            //if cover animation lottie entry seq was played - play leave animation (separated lottie animation)
            return (
                <animated.div style={showCover} className={'absolute z-50 top-0 left-0 w-full h-full'}>
                    <LottieBg theme={coverTheme} mode={'leave'} completed={lottieAniCompleted} />
                </animated.div>
            );
        }
    }

    // swap purple to white background on intro component cover animation leave sequence
    const contentRootBg = !locationRef || locationRef === '/' ? '#e72f75' : '#fff';

    return (
        <div className={'relative z-10 max-w-4xl w-full'} style={{perspective: '500px', perspectiveOrigin: '50%'}}>
            <animated.div style={{
                transformOrigin: 'top',
                transform: bgEntryAnimation.x
                    .to([0, 1], [-100, 0])
                    .to(v => `rotateX(${v}deg)`)
            }} className={'border-solid rounded-2xl bg-color-white p-3 shadow-2xl'}>
                <div style={{backgroundColor: contentRootBg}}
                     className={'relative min-h-[440px] w-full flex justify-between items-center bg-color-white px-8 py-4 rounded-xl border-solid border-[1px] border-color-purple overflow-hidden'}>

                    {renderCover()}

                    <div className={'relative z-10 w-full'}>
                        {transitions((props, item) => {
                            return <animated.div className={''} style={props}>
                                {item === '' ? <StepIntro/> : null}
                                {item === 'websiteurl' ? <StepWebsiteUrl/> : null}
                                {item === 'websiteabout' ? <StepWebsiteAbout/> : null}
                            </animated.div>
                        })}
                    </div>
                </div>
            </animated.div>
        </div>
    );
};

// solution not using react-spring for "outlet / dynamic content" content replacement

// const ContentRoot = () => {
//
//     const location = useLocation().pathname.substr(1);
//
//     const [content, setContent] = useState();
//
//     const [maskAni, apiMaskAni] = useSpring(() => ({opacity: 0}))
//
//     useEffect(() => {
//         apiMaskAni.start({
//             to: [
//                 {
//                     opacity: 1, onRest: () => {
//                         if (location === '') {
//                             setContent(<StepIntro/>);
//                         } else if (location === 'websiteinfo') {
//                             setContent(<StepWebsiteInfo/>);
//                         } else if (location === 'about') {
//                             setContent(<StepAbout/>);
//                         }
//                     }
//                 },
//                 {
//                     opacity: 0, onStart: () => {
//                     }
//                 }
//             ]
//         });
//     }, [location, apiMaskAni]);
//
//     return (
//         <div className={'w-[700px] h-[500px] relative rounded-sm'}>
//             <animated.div style={maskAni} className={'absolute w-full h-full z-20 bg-color-white'}></animated.div>
//             <div className={'relative z-10'}>
//                 {content}
//             </div>
//
//         </div>
//     );
// };

export default ContentRoot;

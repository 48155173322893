import React from 'react';
import ReactPlayer from "react-player";

import movieLogoInit from "../movies/final_resize.mp4";
import movieWebsiteInfo from "../movies/about.mp4";

import videoPlayerPoster from '../img/videoplayer_poster.png';

const VideoLoader = ({video, onEnded}) => {

    const intro  = video === 'StepIntro';
    const url = video === 'StepIntro' ? movieLogoInit
            : video === 'StepWebsiteUrl' ? movieWebsiteInfo
            : '';


    return (
        <>
            {
                intro && <div className={'video-container w-full h-full max-w-[400px] max-h-[400px]'}>
                    <ReactPlayer poster={videoPlayerPoster} onEnded={onEnded} url={url} playing muted width={'100%'} height={'100%'}/>
                </div>
            }
            {
                !intro && <div className={'video-container p-4 pr-0 w-full h-full max-w-[340px] max-h-[340px]'}>
                    <ReactPlayer poster={videoPlayerPoster} onEnded={onEnded} url={url} loop playing muted width={'100%'} height={'100%'}/>
                </div>
            }

        </>
    );
};

export default VideoLoader;

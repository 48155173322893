import React, {useEffect, useState} from 'react'
import Lottie from 'react-lottie-player'
import Loader from "./Loader";
// Alternatively:
// import Lottie from 'react-lottie-player/dist/LottiePlayerLight'

export default function LottieBg({mode, play, completed, theme}) {
    const [animationData, setAnimationData] = useState();

    let file = '';

    if(theme === 'purple'){
        file = mode === 'enter' ? 'bg_enter_purple.json' : 'bg_leave_purple.json';
    } else {
        file = mode === 'enter' ? 'bg_enter_white.json' : 'bg_leave_white.json';
    }

    useEffect(() => {
        import(`../lottie/${file}`).then(setAnimationData);
    }, [file]);

    if (!animationData) {
        return (
            <div className={'w-full h-full flex justify-center items-center'}>
                <Loader/>
            </div>
        );
    }
    return (
        <Lottie play={true} onComplete={() => {completed(mode)}} loop={false} animationData={animationData}/>
    );
}


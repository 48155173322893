import React from 'react';

const Loader = () => {
    return (
        <>
            <span className="loader w-2 h-2 block m-6 flex justify-center items-center"> <span className={"block w-4 h-4 bg-red-500 rounded-full"}></span> </span>
            {/*<div className="lds-roller">*/}
            {/*    <div></div>*/}
            {/*    <div></div>*/}
            {/*    <div></div>*/}
            {/*    <div></div>*/}
            {/*    <div></div>*/}
            {/*    <div></div>*/}
            {/*    <div></div>*/}
            {/*    <div></div>*/}
            {/*</div>*/}
        </>
    );
};

export default Loader;

import {createContext, useReducer} from "react";
import appReducer, {actions} from "./appReducer";

const AppContext = createContext();
export default AppContext;

const initAppReducer = {
    name: '',
    domain: ''
}

export const AppProvider = ({children}) => {

    const [appState, dispatch] = useReducer(appReducer, initAppReducer);

    const setDomain = (domain) => {
        dispatch({type: actions.SET_DOMAIN, payload: domain});
    }
    const setName = (name) => {
        dispatch({type: actions.SET_NAME, payload: name});
    }

    const value = {
        appState,
        setDomain,
        setName
    }

    return (
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    );

}



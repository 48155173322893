import React from "react";
import icoArrowShortPurple from "../img/ico-arrow-short-purple.svg";
import icoArrowShortWhite from "../img/ico-arrow-short-white.svg";
import icoArrowShortGray from "../img/ico-arrow-short-gray.svg";
import {useSpring, animated} from "react-spring";
import useHover from "react-use-hover";
import {useNavigate} from "react-router-dom";

const StepArrowLink = ({to, theme, showTooltip, changeSlideTo, disabled, disabledMsg, delayAnimation}) => {

    const [isHovering, hoverProps] = useHover();

    const {tooltipHeader, tooltipText} = showTooltip;

    const navigate = useNavigate();

    const {color: borderColor} = useSpring({
        from: {color: theme === 'purple' ? '#e72f75' : '#fff'},
        to: {color: disabled ? '#333' : theme === 'purple' ? '#e72f75' : '#fff'}
    })

    const arrowThemeImg = disabled ? icoArrowShortGray : theme === 'purple' ? icoArrowShortPurple : icoArrowShortWhite;

    const aniButton = useSpring({
        transform: 'scale(1)',
        from: {
            transform: 'scale(0)',
        },
        delay: delayAnimation || 500,
        config: { mass: 1, tension: 170, friction: 10 }
    })

    const aniTooltip = useSpring({
        from: {opacity: 0, transform: 'scale(0.2)'},
        to: isHovering ? {opacity: 1, transform: 'scale(1)'} : {opacity: 0, transform: 'scale(0.2)'},
        config: {
            tension: 300, friction: 8, mass: 1, clamp: true
        }
    })

    // const aniTooltipNew = useSpring({
    //     from: {
    //         transform: 'rotateY(-20deg) rotateX(-35deg) skew(-35deg, 10deg)',
    //         opacity: '0'
    //     },
    //     to: {
    //         transform: isHovering ? 'rotateY(0deg) rotateX(0deg) skew(0deg, 0deg)' : 'rotateY(-20deg) rotateX(-35deg) skew(-35deg, 10deg)',
    //         opacity: isHovering ? '1' : '0'
    //     },
    //     config: {
    //         tension: 120, friction: 14, mass: 1
    //     }
    // })

    const tooltipTxtColor = disabled ? '#333' : '#e72f75';

    const btnBgColor = !disabled && isHovering ? 'rgba(251,186,0,1)' : disabled && isHovering ? 'rgba(0,0,0,0.15)' : 'rgba(251,186,0,0)';

    return (
        <animated.a {...hoverProps} style={{borderColor, backgroundColor: btnBgColor, ...aniButton}} onClick={() => {!disabled && navigate(to)}} className={`absolute flex transition-colors z-10 group -right-6 cursor-pointer justify-center items-center p-3 w-12 h-12 rounded-full border-solid border-2`}>
            <animated.div style={aniTooltip}
                className={'absolute origin-bottom-left z-20 left-4 bottom-8 transition-all opacity-0 drop-shadow-xl min-w-[240px]'}>
                <div style={{color: tooltipTxtColor}} className={'relative bg-color-white p-3 rounded rotate-6 border-2 border-color-purple3'}>
                    <div className={'absolute w-4 h-4 bg-color-white rotate-45 skew-y-12 -bottom-1'}> </div>
                    <span className={'relative font-bold'}>
                        {disabled ? <span className={'font-normal'}>{disabledMsg}</span> : <div>
                            <h5 className={'text-color-purple'}>{tooltipHeader}</h5>
                            <span className={'text-color-grey font-normal leading-5 block'}>{tooltipText}</span>
                        </div> }
                    </span>
                </div>
            </animated.div>
            <img className={''} src={arrowThemeImg} alt=""/>
            {/*pulsing cirlce*/}
            <animated.div style={{borderColor: borderColor}} className={`animate-[pulse_1.5s_ease-in-out_0s_infinite] w-12 h-12 rounded-full border-solid border-2 absolute opacity-30 group-hover:hidden`}> </animated.div>
            {/*rotating circle*/}
            {!disabled && <animated.div style={{borderColor: borderColor}} className={`animate-[rotate_1.5s_linear_0s_infinite] w-16 h-16 rounded-full border-dashed border-2 absolute opacity-0 group-hover:opacity-50 transition-all ease-out duration-500`}> </animated.div>}
        </animated.a>
    );
};

export default StepArrowLink;

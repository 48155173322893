export const actions = {
    SET_DOMAIN: 'SET_DOMAIN',
    SET_NAME: 'SET_NAME',
}

export default function appReducer(state, action) {
    if(action.type === actions.SET_DOMAIN){
        return {...state, domain: action.payload}
    }
    if(action.type === actions.SET_NAME){
        return {...state, name: action.payload}
    }
    throw new Error('Invalid action type');
}

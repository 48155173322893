import React from 'react';
import StepArrowLink from "../components/StepArrowLink";
import VideoLoader from "../components/VideoLoader";
import {animated, useSpring} from "react-spring";

const StepIntro = () => {

    const [aniColText, apiAniColText] = useSpring(() => ({width: '0%', flex: '0'}));
    const [aniText, apiAniText] = useSpring(() => ({opacity: '0', left: '-80px', display: 'none'}));

    const onIntroEnded = () => {
        apiAniColText({width: '50%', flex: '1'})
        apiAniText({opacity: '1', left: '0px', display: 'flex', delay: 800})
    }

    const title = 'It’s Time to Get Seen, Selling and Sorted.';
    const subtitle = 'Follow the steps and we’ll send you a free report.';
    const description = 'You will get valuable tips and insights about your website’s visibility online, as well as the scoop on your competitors.';

    // const aniFreeReport = useSpring({
    //     from: {color: '#fbba00'},
    //     to: {color: '#ffffff'},
    //     loop: { reverse: true },
    //     autoStart: true,
    //     config: {
    //         tension: 80, friction: 27
    //     }
    // })

    return (
        <div className={'relative z-10 flex flex-row w-full overflow-hidden'}>
            <animated.div style={aniColText} className={'relative z-20 flex flex-col justify-center items-start'}>
                <animated.div style={aniText} className={'relative flex-col justify-center pr-12'}>
                    <h2 className={'text-color-white text-3xl font-bold pb-4'}>{title}</h2>
                    <h4 className={'text-color-white pb-4 text-color-orange font-bold text-lg'}>{subtitle}</h4>
                    <p className={'text-color-white pb-4'}>{description}</p>
                        <StepArrowLink to={'/websiteurl'} theme='white' showTooltip={{tooltipHeader: 'Let\'s go!'}} delayAnimation={6000} />
                </animated.div>
            </animated.div>
            <div className={'flex flex-1 justify-center relative z-10'}>
                <VideoLoader onEnded={onIntroEnded} video={'StepIntro'} />
            </div>
        </div>
    );
};

export default StepIntro;

import React, {useContext} from 'react';
import StepArrowLink from "../components/StepArrowLink";
import VideoLoader from "../components/VideoLoader";
import AppContext from "../AppContext";

const StepWebsiteAbout = () => {

    const {appState} = useContext(AppContext);
    const {domain} = appState

    const title = 'OK! So that is your website';
    const subtitle = 'Let\'s see what it\'s doing for you';
    const description = 'some text content';

    return (
        <div className={'relative z-10 flex flex-row w-full overflow-hidden'}>
            <div className={'relative z-20 flex flex-col justify-center flex-1'}>
                <div className={'relative flex-col flex justify-center pr-12 '}>
                    <h2 className={'text-color-purple text-3xl font-bold pb-2'}>{title}</h2>
                    <h4 className={'text-color-orange font-bold text-lg pb-2'}>{subtitle}</h4>
                    <p className={'text-color-gray pb-4'}>{description}</p>
                    <StepArrowLink to={'websiteurl'}
                                   theme='purple'
                                   showTooltip="OK so that's your website!"
                                   disabled={false}
                                   disabledMsg={'Drop you website url please'} />
                </div>
            </div>
            <div className={'relative flex justify-center z-10 flex-1 overflow-hidden'}>
                <div className={'absolute w-full h-24 top-0 left-0 z-10 bg-gradient-to-b from-color-white to-color-[rgba(255,255,255,0)]'}> </div>
                <div className={'absolute w-full h-36 bottom-0 left-0 z-10 bg-gradient-to-t from-color-white to-color-[rgba(255,255,255,0)]'}> </div>
                <div className="relative flex items-center h-[400px]">
                    <iframe className={'w-[1280px] h-[1600px] scale-[0.25] ml-10 border-[10px] border-color-purple3 rounded-[20px]'} src={`https://www.${domain}`} frameBorder="0"> </iframe>
                </div>
            </div>
        </div>
    );
};

export default StepWebsiteAbout;

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// import reportWebVitals from './reportWebVitals';

import {AppProvider} from "./AppContext";
import {BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <AppProvider>
          <BrowserRouter basename="/">
            <App />
          </BrowserRouter>
      </AppProvider>
  </React.StrictMode>
);

// reportWebVitals();
